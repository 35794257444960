<template>

  <div id="main" class="main" @click="show = !show">
<!--    <div class="mod_container">-->
<!--      <div id="mod" class="mod" @click="playMusic">-->
<!--        <van-loading class="mod-load" type="spinner" size="30px" v-if="showLoadIcon"></van-loading>-->
<!--      </div>-->
<!--    </div>-->
    <div   :style="{width: '100%',height: '100%'}">
      <van-loading class="mod-load" type="spinner" size="30px" v-if="showLoadIcon"></van-loading>
      <div id="fullScreenContainer" class="fullScreenContainer">



      </div>
    </div>
  </div>

<!--  @opened="initFS3D" @closed="closeFS3D" overlay-class="mask_style" closeable-->
<!--  :close-on-click-overlay="true"  lazy-render close-on-popstate safe-area-inset-bottom-->
<!--  get-container=".main"-->

</template>

<script>

import {ref, reactive, onMounted, toRefs, getCurrentInstance, onUnmounted} from "vue";
import { RouterView, useRouter,onBeforeRouteLeave } from "vue-router";
import path from "path";
import * as THREE from 'three';
import {
  Scene,
  PerspectiveCamera,
  Color,
  WebGLRenderer,
  LinearToneMapping,
  AmbientLight,
  Vector3,
  Group,
  PMREMGenerator,
  UnsignedByteType,
  HemisphereLight,
} from 'three';

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
// import WebGPU from 'three/examples/jsm/capabilities/WebGPU.js';
// import WebGPURenderer from 'three/examples/jsm/renderers/webgpu/WebGPURenderer';
import ResourceTracker, {clearToast, showFailToast,getInervalHour, showLoading} from "../../utils/util";

export default {
  name: "view3D",
  setup() {

    document.title = '3D预览'
    let resMgr = new ResourceTracker();
    const track = resMgr.track.bind(resMgr);
    const { proxy } = getCurrentInstance();
    console.log("proxy", proxy)
    const $router = useRouter();
    const zoomFactor =ref(17)
    console.log($router.currentRoute.value.query.index)
    const state = reactive({
      index: $router.currentRoute.value.query.index || 1,
      hdr: $router.currentRoute.value.query.hdr || 1,
      autoRotate:  $router.currentRoute.value.query.autoRotate ?? false,
      product: {
        name: '',
        hash_code: "",
        releaseHashCode:"",
        images: "",
        issuer: "",
        productName: "",
        releaseTime: "",
        storyImages: "",
        total: "",
        lables: "",
        startTime: "",
        dateDiff: 0,
        hourDiff: 0,
        id: "",
        number:"",
        hashCode:"",
        creator:"",
        shareImage:"",
        saleTime:"",
        threedSize:20,
        seckillStock:0,
        isReady:false
      },
      productInfo: {
        number:"",
      },
      myProductDetail: {
        userName:"",
        generatorTime:"",
        hashCode:"",
      },
      transferForm:{},
      show:true
    });
    let threeD = {
      group: null,
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      degree: 0,
      width: document.documentElement.clientWidth,
      height: 0,
      clock: null,
      axisHelper: null,
      mainControls:null,
      controls: null,
      gltfLoader: null,
      dracoLoader: null,
      decoderPath: '/model/draco/',
      decoderConfigType: 'js',
      gltfPath: '',
      envPath: '/model/env/hdr-qfmy-js.hdr',
      spotLight: null,
      hemiLight: null,
      glb: null,
      pos: null
    }
    const showLoadIcon=ref(true)

    onMounted(() => {

      // threeD.gltfPath = res.data.data.threeD
      // threeD.envPath = res.data.data.threedLight
      init()
      animate()
      initFS3D();

    })
    onUnmounted(()=>{
      cancelPlay();
    })
    onBeforeRouteLeave((to, from, next) => {
      cancelPlay()
      clearScene()
      next()
    })





    const showPopup = (()=>{
      state.show = true
    })
    /*const getInervalHour = (startDate, endDate)=>{
      let ms = endDate.getTime() - startDate.getTime();
      if (ms < 0) return 0;
      return Math.floor(ms/1000/60/60);
    }*/


    const initFS3D = (() => {
      let container= document.querySelector('#fullScreenContainer')
      container.appendChild(threeD.renderer.domElement)
      //threeD.controls.enabled = !threeD.controls.enabled
      initControls(true)
    })
    const closeFS3D= (() => {
      let container= document.querySelector('#main')
      container.appendChild(threeD.renderer.domElement)
      initControls(false)
      //threeD.controls.enabled = !threeD.controls.enabled
    })
    const init = (() => {
      threeD.scene = new THREE.Scene()
      threeD.group = new THREE.Group()
      //threeD.clock = new THREE.Clock()//默认1s60帧 这样就可以进行循环动画
      threeD.pos = new Vector3(0, 0, 0)
      initRenderer(state.show)
      initCamera()
      initControls(true)
      // initLight()
      initHDRMapping()
      initGltfLoader()
      //initSpotLight()
    })
    const animate = (() => {
      threeD.renderer.setAnimationLoop(render.bind())
      //requestAnimationFrame(threeD.animate)//渲染循环，以每秒60次的频率来绘制场景
      // threeD.cube.rotation.y += 0.005
      //threeD.scene.dispose()
      //render()
    })
    const render = (() => {
      //const d = (++degree * Math.PI) / 180;
      threeD.controls.update()//触发事件
      //const spt = threeD.clock.getDelta()
      //console.log('渲染间隔时间',spt);
      //threeD.spotLight.position.set(threeD.camera.position.x,threeD.camera.position.y,threeD.camera.position.z);
      threeD.renderer.render(threeD.scene, threeD.camera) //渲染，显示摄像头在屏幕上看到的内容
      //threeD.group.rotateY(-0.01)//使模型围绕y轴旋转
      //threeD.group.rotation.y -=0.006//使模型围绕y轴旋转
      //group.rotation.set(0, d, 0);
    })
    const initRenderer = ((isFullScreen) => {
      threeD.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })//创建渲染器，将相机看到的内容绘制到屏幕上
      // threeD.renderer = new WebGPURenderer();
      let container= document.querySelector('#main')
      // threeD.renderer.setSize( container.clientHeight , 1000*container.clientHeight/988.0)
      threeD.renderer.setSize(container.clientWidth, container.clientHeight);//渲染器canvas宽高设为与窗口一致
      container.appendChild(threeD.renderer.domElement)
      // console.log(threeD.width)
      // console.log(threeD.height)
      threeD.renderer.outputEncoding = THREE.sRGBEncoding
      threeD.renderer.setPixelRatio(devicePixelRatio);
      threeD.renderer.toneMapping = THREE.LinearToneMapping;
      threeD.renderer.toneMapping = THREE.ACESFilmicToneMapping;//色调映射-电影级别
      threeD.renderer.toneMappingExposure = 1.6; // 曝光系数
    })

    const initCamera = (() => {
      let container= document.querySelector('#main')
      threeD.camera = new THREE.PerspectiveCamera(18, container.clientWidth / container.clientHeight, 1, 10000); //摄像机视角15度，画幅比例等
      threeD.camera.position.set(2600, 60, 2800) //摄像机位置
      threeD.camera.lookAt(threeD.pos.x, threeD.pos.y, threeD.pos.z)//相机看向那个点
      // threeD.camera.aspect = devicePixelRatio;
      // threeD.camera.updateProjectionMatrix()
      threeD.scene.add(track(threeD.camera))
      // threeD.axisHelper = new THREE.AxesHelper(10);//添加辅助坐标系坐标轴 红x 绿y 蓝z 颜色可以后期自己设置 参数是坐标轴长短 太小就会在物体里面
    })
    const initControls = ((isFullScreen) => {
      if (isFullScreen) {
        threeD.controls = new OrbitControls(threeD.camera, threeD.renderer.domElement)//相机控件
        threeD.controls.minDistance = 500
        threeD.controls.maxDistance = 3000
        threeD.controls.minPolarAngle = 0
        threeD.controls.maxPolarAngle = 2.5//Math.PI / 2
        threeD.controls.enableDamping = true //设置阻尼也就是惯性 这个设置完必须在渲染的每帧里面执行updata方法(在动画循环里调用.update())
        threeD.controls.target.set(threeD.pos.x, threeD.pos.y, threeD.pos.z)//跟相机的lookAt坐标一致
        threeD.controls.autoRotate =  state.autoRotate ;
        threeD.controls.enablePan = true // 是否开启右键拖拽
        threeD.controls.dampingFactor = 0.5 // 动态阻尼系数 就是鼠标拖拽旋转灵敏度，阻尼越小越灵敏
        threeD.controls.rotateSpeed = 2
      } else {
        initCamera()
        threeD.controls = new OrbitControls(threeD.camera, threeD.renderer.domElement)//相机控件
        threeD.controls.minDistance = 20
        threeD.controls.maxDistance = 3000
        threeD.controls.minPolarAngle = 0
        threeD.controls.maxPolarAngle = 2.5//Math.PI / 2
        // threeD.controls.autoRotate = true
        threeD.controls.enableDamping = true
        threeD.controls.enablePan = true // 是否开启右键拖拽
        threeD.controls.autoRotateSpeed = 4.0
        threeD.controls.enableZoom = false
        threeD.controls.enableRotate = false
        threeD.controls.enablePan = false
        threeD.controls.target.set(threeD.pos.x, threeD.pos.y, threeD.pos.z)//跟相机的lookAt坐标一致
      }
    })

    const resetOrbitControls = () => {
      threeD.controls.minDistance = 500
      threeD.controls.maxDistance = 3000
      threeD.controls.minPolarAngle = 0
      threeD.controls.maxPolarAngle = 2.5//Math.PI / 2
      threeD.controls.enableDamping = true //设置阻尼也就是惯性 这个设置完必须在渲染的每帧里面执行updata方法(在动画循环里调用.update())
      threeD.controls.target.set(threeD.pos.x, threeD.pos.y, threeD.pos.z)//跟相机的lookAt坐标一致
      threeD.controls.autoRotate = false
      threeD.controls.enablePan = true // 是否开启右键拖拽
      threeD.controls.dampingFactor = 0.5 // 动态阻尼系数 就是鼠标拖拽旋转灵敏度，阻尼越小越灵敏
      threeD.controls.rotateSpeed = 2
    }
    const initSpotLight = (() => {
      threeD.spotLight = new THREE.SpotLight(0xFFFFFF)
      threeD.spotLight.position.set(500, 40, 500)
      threeD.spotLight.castShadow = true
      threeD.spotLight.shadow.mapSize = new THREE.Vector2(1024, 1024)
      threeD.spotLight.shadow.camera.far = 130
      threeD.spotLight.shadow.camera.near = 40
      threeD.spotLight.distance = 800
      threeD.spotLight.angle = 0.8
      threeD.spotLight.shadow.camera.fov = 120
      threeD.spotLight.decay = 2
      threeD.spotLight.intensity = 0.5
      threeD.spotLight.power = 10
      threeD.scene.add(track(threeD.spotLight))
    })
    const initLight = (() => {
      const ambient = new THREE.AmbientLight(0xffffff, 1)//添加环境光
      threeD.scene.add(track(ambient))
      const hemiLight = new HemisphereLight(0xffffff, 0x000000, 1);
      hemiLight.position.set(0, 100, 0);
      threeD.scene.add(track(hemiLight));
      const pointLight = new THREE.PointLight(0xffffff, 1.4)//添加点光源
      pointLight.position.set(200, 100, 200)//设置点光位置
      //threeD.scene.add(pointLight)
      //threeD.camera.add(pointLight)
      const pointLightHelper = new THREE.PointLightHelper(pointLight, 50);// 可视化点光源
      //threeD.scene.add(pointLightHelper);
    })
    const initHDRMapping = (() => {
      const pmremGenerator = new PMREMGenerator(threeD.renderer); // 使用hdr作为背景色
      pmremGenerator.compileEquirectangularShader();
      const rgbLoader = new RGBELoader();
      rgbLoader.loadAsync(window.location.origin + (state.index > 1 ? ('/hdr' + state.hdr + '.hdr') : '/hdr.hdr')).then((texture) => {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        //threeD.scene.background = texture;//将加载的材质texture设置给背景和环境
        threeD.scene.environment = texture;
      })
    })
    const initGltfLoader = (() => {
      threeD.gltfLoader = track(new GLTFLoader())
      threeD.dracoLoader = track(new DRACOLoader())
      threeD.dracoLoader.setDecoderPath(threeD.decoderPath)
      threeD.dracoLoader.preload()
      threeD.dracoLoader.setDecoderConfig({ type: threeD.decoderConfigType })
      threeD.gltfLoader.setDRACOLoader(threeD.dracoLoader)
      threeD.gltfLoader.load(window.location.origin + (state.index > 0 ? '/3d' + state.index + '.glb' : '/3d1.glb'), (glb) => {
        //threeD.scene.add(track(glb.scene));
        //glb.scene.position.set(0,0,0)
        console.log(glb)
        glb.scene.scale.set(parseInt(state.product.threedSize)*zoomFactor.value,
          parseInt(state.product.threedSize)*zoomFactor.value,
          parseInt(state.product.threedSize)*zoomFactor.value);//网格模型缩放

        threeD.v1T1 = new THREE.Vector3(glb.scene.x, glb.scene.y, glb.scene.z)
        //threeD.spotLight.target = glb.scene
        threeD.glb = track(glb.scene)
        threeD.group.add(threeD.glb)
        threeD.scene.add(track(threeD.group))
        threeD.group.position.set(threeD.pos.x, threeD.pos.y, threeD.pos.z)
        showLoadIcon.value=false
      }, undefined, function (error) {
        // showLoadIcon.value=false 未加载出来也移除加载中状态
        console.error(error);
      });
    })
    const clearScene = (() => {
      if(threeD.scene){
        cancelAnimationFrame(animate);
        /*threeD.scene.traverse((child) => {
          if (child.material) {
            child.material.dispose();
          }
          if (child.geometry) {
            child.geometry.dispose();
          }
          child = null;
        });*/
        //threeD.sceneDomElement.innerHTML = '';
        threeD.renderer.forceContextLoss();
        threeD.renderer.dispose();
        threeD.scene.clear();
        threeD.scene = null;
        threeD.camera = null;
        threeD.controls = null;
        threeD.renderer.domElement = null;
        threeD.renderer = null;
        threeD.sceneDomElement = null;
      }
      console.log('clearScene');
    })


    let player;
    let videoIsPlay = false;
    //-----------音乐播放-----------------------
    const playMusic = () => {


      if(showLoadIcon.value){
        return;
      }


      if( state.product.threedMusicUrl.length > 0){
        if(player != null){
          player.pause();
          player = null;
          videoIsPlay =  false;
        }
        player = new Audio(state.product.threedMusicUrl);
        player.play();
        videoIsPlay =  true;
        player.addEventListener('ended', function(){
          videoIsPlay =  false;
        },false);
      }


    }

    const cancelPlay = () => {
      if(player != null){
        player.pause();
        player = null;
        videoIsPlay =  false;
      }
    }




    return {
      resetOrbitControls,
      playMusic,
      ...toRefs(state),
      showPopup,
      showLoadIcon,
      init,
      animate,
      render,
      initRenderer,
      initCamera,
      initControls,
      initSpotLight,
      initLight,
      initHDRMapping,
      initGltfLoader,
      initFS3D,
      closeFS3D,
      name,
    };
  }
}
</script>

<style scoped lang="scss" >

.fullScreenContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/3dbackground.png');
  background-size: 100% 100%;



}

.mod-load {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
}
.main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #222;

.mod_container {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/3dbackground.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-size:contain;


  .mod{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;

    :deep(canvas) {
      touch-action: unset !important;
    }
    .mod-load {
      width: 50px;
      min-height: 50px;
      line-height: 500px;
      display:table-cell;
      vertical-align: middle;
      align-self:center;
    }
  }

}

}


</style>
